// extracted by mini-css-extract-plugin
export var head = "Pillars-module--head--a28b7";
export var heroBackgroundInnerCircleCenter = "Pillars-module--heroBackgroundInnerCircleCenter--cf63c";
export var heroBackgroundInnerCircleLeft = "Pillars-module--heroBackgroundInnerCircleLeft--56209";
export var heroBackgroundInnerCircleLeft1 = "Pillars-module--heroBackgroundInnerCircleLeft1--460fa";
export var heroBackgroundInnerCircleLeft2 = "Pillars-module--heroBackgroundInnerCircleLeft2--17b71";
export var heroBackgroundInnerCircleRight = "Pillars-module--heroBackgroundInnerCircleRight--106e3";
export var pillars = "Pillars-module--pillars--a4578";
export var pillarsDescription = "Pillars-module--pillarsDescription--a9eb5";
export var pillarsDescriptionTitle = "Pillars-module--pillarsDescriptionTitle--4e9e2";
export var pillarsImage = "Pillars-module--pillarsImage--01ff9";
export var pillarsItem = "Pillars-module--pillarsItem--8058d";
export var pillarsNumber = "Pillars-module--pillarsNumber--c3f05";